{
  "name": "goplanner",
  "version": "0.9.0",
  "description": "",
  "scripts": {
    "start": "ng serve",
    "build-serve": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng serve",
    "ng": "ng",
    "build": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build && pnpm sentry:sourcemaps && pnpm transloco:optimize",
    "build:staging": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --configuration staging",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test:watch": "ng test --watch",
    "test:ci": "ng test --runInBand",
    "e2e": "playwright test",
    "e2e:debug": "playwright test --project chromium --headed",
    "compodoc:build": "compodoc -p tsconfig.doc.json --includes ./guidelines",
    "compodoc:build-and-serve": "compodoc -p tsconfig.doc.json -s --includes ./guidelines",
    "compodoc:serve": "compodoc -s --includes ./guidelines",
    "prepare": "husky || true",
    "lint": "ng lint",
    "format": "prettier --config .prettierrc.json -w .",
    "transloco:optimize": "transloco-optimize dist/goplanner/assets/i18n",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org goplanner --project app ./dist/goplanner && sentry-cli sourcemaps upload --org goplanner --project app ./dist/goplanner"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.11",
    "@angular/cdk": "^18.2.12",
    "@angular/common": "^18.2.11",
    "@angular/compiler": "^18.2.11",
    "@angular/core": "^18.2.11",
    "@angular/forms": "^18.2.11",
    "@angular/platform-browser": "^18.2.11",
    "@angular/platform-browser-dynamic": "^18.2.11",
    "@angular/platform-server": "^18.2.11",
    "@angular/router": "^18.2.11",
    "@angular/service-worker": "^18.2.11",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/list": "^6.1.15",
    "@fullcalendar/multimonth": "^6.1.15",
    "@fullcalendar/resource": "^6.1.15",
    "@fullcalendar/resource-timegrid": "^6.1.15",
    "@fullcalendar/resource-timeline": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@goplanner/api-client": "0.2.0-1758",
    "@goplanner/form-builder": "0.2.0-1758",
    "@goplanner/grid-builder": "0.2.0-1758",
    "@goplanner/module-builder": "0.2.0-1758",
    "@jsverse/transloco": "^7.5.0",
    "@jsverse/transloco-locale": "^7.0.1",
    "@ngrx/component": "^18.1.1",
    "@ngrx/data": "^18.1.1",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/entity": "^18.1.1",
    "@ngrx/operators": "^18.1.1",
    "@ngrx/router-store": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngrx/store-devtools": "^18.1.1",
    "@ngx-formly/core": "^6.3.10",
    "@satinel-system/calendar": "0.0.14-64",
    "@satinel-system/core": "0.0.14-64",
    "@satinel-system/document-management": "0.0.14-64",
    "@satinel-system/form": "0.0.14-64",
    "@satinel-system/grid": "0.0.14-64",
    "@satinel-system/map": "0.0.14-64",
    "@satinel-system/stats": "0.0.14-64",
    "@sentry/angular": "^8.37.1",
    "@sentry/cli": "^2.38.2",
    "@tinkoff/ng-dompurify": "^4.0.0",
    "@turf/turf": "^6.5.0",
    "chart.js": "^4.4.6",
    "chokidar": "^3.6.0",
    "dygraphs": "^2.2.1",
    "file-saver": "^2.0.5",
    "fullcalendar": "^6.1.15",
    "lodash": "^4.17.21",
    "mapbox-gl": "3.4.0",
    "ng-qrcode": "^18.0.0",
    "ngx-extended-pdf-viewer": "21.4.1",
    "ngx-quicklink": "0.4.6",
    "normalize.css": "^8.0.1",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "17.18.8",
    "quill": "^2.0.2",
    "rxjs": "~7.8.1",
    "three": "^0.170.0",
    "tslib": "^2.8.1",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular-devkit/core": "~18.2.11",
    "@angular-devkit/schematics": "~18.2.11",
    "@angular-eslint/builder": "18.4.0",
    "@angular-eslint/eslint-plugin": "18.4.0",
    "@angular-eslint/eslint-plugin-template": "18.4.0",
    "@angular-eslint/schematics": "18.4.0",
    "@angular-eslint/template-parser": "18.4.0",
    "@angular/cli": "~18.2.11",
    "@angular/compiler-cli": "^18.2.11",
    "@babel/core": "^7.26.0",
    "@commitlint/cli": "^19.5.0",
    "@commitlint/config-angular": "^19.5.0",
    "@compodoc/compodoc": "^1.1.26",
    "@jsverse/transloco-optimize": "^7.0.1",
    "@ngrx/eslint-plugin": "^18.1.1",
    "@ngrx/schematics": "^18.1.1",
    "@ngx-formly/schematics": "^6.3.10",
    "@playwright/test": "^1.48.2",
    "@schematics/angular": "~18.2.11",
    "@testing-library/angular": "^17.3.2",
    "@types/dygraphs": "^2.1.10",
    "@types/fabric": "^5.3.9",
    "@types/geojson": "^7946.0.14",
    "@types/jest": "^29.5.14",
    "@types/lodash": "^4.17.13",
    "@types/mapbox-gl": "^3.4.1",
    "@types/mapbox__mapbox-gl-draw": "^1.4.8",
    "@types/node": "^22.9.0",
    "@types/three": "^0.169.0",
    "@typescript-eslint/eslint-plugin": "8.13.0",
    "@typescript-eslint/parser": "8.13.0",
    "babel-loader": "^9.2.1",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "geojson": "^0.5.0",
    "husky": "^9.1.6",
    "jest": "^29.7.0",
    "jest-util": "^29.7.0",
    "lint-staged": "^15.2.10",
    "prettier": "3.3.3",
    "typescript": "~5.4.5"
  },
  "lint-staged": {
    "src/**/*.(ts|js)": [
      "eslint --fix"
    ],
    "*": "prettier --config .prettierrc.json -w ."
  },
  "packageManager": "pnpm@8.15.9+sha512.499434c9d8fdd1a2794ebf4552b3b25c0a633abcee5bb15e7b5de90f32f47b513aca98cd5cfd001c31f0db454bc3804edccd578501e4ca293a6816166bbd9f81"
}
